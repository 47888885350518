import { RedirectToSignIn } from '@clerk/nextjs'
import MarketingLayout from '@layouts/MarketingLayout'

export default function Home(props) {
	return (
		<>
			<RedirectToSignIn />
		</>
	)
}
Home.layoutProps = {
	Layout: MarketingLayout,
	title: 'Home',
}
