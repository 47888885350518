import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

/**
 * Show Component when route change is complete
 */
export function useRouterEvents(show) {
	const router = useRouter()
	const [isShowing, setIsShowing] = useState(show)

	useEffect(() => {
		const handleStart = () => setIsShowing(false)
		const handleComplete = () => setIsShowing(true)

		router.events.on('routeChangeStart', handleStart)
		router.events.on('routeChangeComplete', handleComplete)
		router.events.on('routeChangeError', handleComplete)

		return () => {
			router.events.off('routeChangeStart', handleStart)
			router.events.off('routeChangeComplete', handleComplete)
			router.events.off('routeChangeError', handleComplete)
		}
	}, [router])

	return isShowing
}
