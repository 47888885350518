import { Transition } from '@headlessui/react'
import { useRouterEvents } from '@hooks/useRouterEvents'

export default function MarketingLayout({ children }): JSX.Element {
	const isShowing = useRouterEvents(true)

	return (
		<div className="flex flex-col w-full overflow-x-hidden">
			<main className="w-full min-h-screen">
				<Transition
					appear={true}
					show={isShowing}
					enter="transition-opacity duration-250"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity duration-150"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					{children}
				</Transition>
			</main>
		</div>
	)
}
